<template>
	<div class="needBox">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<div class="infortion1">需求信息</div>
			<div class="baseInformtion">
				<el-form-item label="" prop="Title" label-width="0px">
					<span style="color: red;margin-right: 4px;">*</span>
					<label>需求名称</label>
					<el-input maxlength="50" v-model="ruleForm.Title" size="small" style="width:750px;margin-left: 20px;" clearable
					 placeholder="请输入需求名称,最多50字,例如想要xxxx功能"></el-input>
				</el-form-item>
			</div>
			<div class="baseInformtion" style="margin-bottom: 150px;">
				<span style="color: red;margin-right: 4px;">*</span><label style="font-size: 14px;">需求详情</label>
				<!-- margin-bottom:50px;margin-left:90px; -->
				<div class="baseBottom-box" style="width:80%;position: relative;">
					<quill-editor @change="onEditorChange($event,ruleForm.Details,10000)" ref="myTextEditor" v-model="ruleForm.Details"
					 :options="quillOption" style="width:100%;min-height: 300px;padding-left:90px;" placeholder='123'></quill-editor>
					 <span style='position:absolute;bottom:10px;right:20px;'>{{editorLength}}/10000</span>
				</div>
			</div>
		</el-form>

		<!-- 底部保存 -->
		<div class="bottom-save-btn">
			<el-button style="width:240px" @click="canselGoods">取消</el-button>
			<el-button style="width:240px;margin:0 10px 0 20px" type="primary" @click="submitForm('ruleForm')" :loading="loading">提交需求</el-button>
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		quillEditor
	} from 'vue-quill-editor'
	import quillConfig from '@/components/quill-config1.js'
	import {
		SubmitMallDemand
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			quillEditor,
		},
		beforeMount() {
			this.quillOption.placeholder ='为了让开发团队了解您的需求，请您填写需求详情（包含需求背景和需求描述），例如：我的店铺有上千个会员，现在需要对会员的消费行为进行深度的分析，所以希望能统计：1.每个会员的消费总额、消费次数；2.能通过时间查询每个会员的消费数据；3.可以导出数据'
		},
		data() {
			return {
				editorLength:0,
				loading: false,
				quillOption: quillConfig,
				ruleForm: {
					Title: '',
					Details: '',
				},
				rules: {
					Title: [{
							required: true,
							message: '请输入需求名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 50,
							message: '长度在 1 到 50 个字符',
							trigger: 'blur'
						}
					],
				}
			}
		},
		methods: {
			onEditorChange(event, strValue, decimalNum) {
				event.quill.deleteText(decimalNum, 1, strValue);
				this.editorLength = event.quill.getLength() - 1
			},
			canselGoods() {
				this.$router.push({
					path: '/myNeed'
				});
			},
			removeHTMLTag(str) {
				str = str.replace(/<\/?[^>]*>/g, '');
				str = str.replace(/[ | ]*\n/g, '\n');
				str = str.replace(/&nbsp;/ig, '');
				str = str.replace(/\s/g, '');
				return str;
			},
			submitForm(formName) {
				if (!this.ruleForm.Title) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入需求名称!'
					});
					return
				}
				if (!this.ruleForm.Details) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入需求详情!'
					});

					return
				}
				// if(this.removeHTMLTag(this.ruleForm.Details).length>10000){
				// 	this.$message({
				// 		showClose: true,
				// 		type: 'error',
				// 		message: '需求详情最多10000字!'
				// 	});

				// 	return
				// }

				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true
						try {
							let data = {
								Title: this.ruleForm.Title,
								Content: this.ruleForm.Details
							}
							let result = await SubmitMallDemand(data)
							if (result.IsSuccess) {
								this.$alert(
									'需求提交成功。与你同行，倍感荣幸。</br>我们将尽快评估需求实现可行性和优先级，请您留意后续版本更新。 感谢您的耐心及支持， 我们会在您的支持下越做越好， 为您提供优质服务， 祝您生意兴隆！ ',
									'', {
										confirmButtonText: '确定',
										dangerouslyUseHTMLString: true,
										showClose: false,
										callback: action => {
											this.$router.push({
												path: '/myNeed'
											});
										}
									});
							}
							console.log()
						} catch (err) {

						} finally {
							this.loading = false
						}
					}
				})
			},
		}

	}
</script>

<style lang="less">
	.needBox {
		height: 100%;
		background: #fff;
		font-size: 15px;
		margin-bottom: 80px;
		.infortion1 {
			padding: 10px 0 10px 10px;
			border-bottom: 1px solid #F5F5F5;
			margin-bottom: 20px;
		}

		.baseInformtion {
			margin-left: 30px;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.quill-editor:not(.bubble) .ql-container,
		.quill-editor:not(.bubble) .ql-container .ql-editor {
			min-height: 300px;
			padding-bottom: 20px;
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

	}
</style>
